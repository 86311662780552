// If you don't want to use TypeScript you can delete this file!
import * as React from 'react';
import { PageProps } from 'gatsby';
import Layout from './layout';
import SEO from './seo';
import ProjectContent from './projectContent';

interface ProjectProps extends PageProps {
  pageContext: {
    project: {
      body: string;
      frontmatter: {
        path: string;
        title: string;
        title_img: string;
        date_obj: {
          date: string;
          date_label: string;
        };
        role_obj: {
          role: string;
          role_label: string;
        };
        skills_tools_obj: {
          skills_tools_label: string;
          skills_tools: string[];
        };
        team_obj?: {
          team?: string;
          team_label?: string;
        };
      };
    };
  };
}

const ProjectPage = ({ pageContext }: ProjectProps) => {
  return (
    <Layout>
      <SEO title="David Hellman" />

      <section>
        <ProjectContent project={pageContext.project} />
      </section>
    </Layout>
  );
};

export default ProjectPage;

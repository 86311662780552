import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { isObjEmpty } from '../utils/helpers';
import '../assets/styles/projectContent.scss';

type ContentProps = {
  project: {
    body: string;
    frontmatter: {
      path: string;
      title: string;
      title_img: string;
      date_obj: {
        date: string;
        date_label: string;
      };
      role_obj: {
        role: string;
        role_label: string;
      };
      skills_tools_obj: {
        skills_tools_label: string;
        skills_tools: string[];
      };
      team_obj?: {
        team?: string;
        team_label?: string;
      };
    };
  };
};

const ProjectContent = ({ project }: ContentProps) => {
  const { body, frontmatter } = project;

  const skillsLen =
    !isObjEmpty(frontmatter.skills_tools_obj) &&
    frontmatter.skills_tools_obj.skills_tools &&
    frontmatter.skills_tools_obj.skills_tools.length;

  return (
    <div>
      <h1>{frontmatter.title}</h1>
      <div className="project-overview">
        <img
          src={frontmatter.title_img}
          alt={`${frontmatter.title} image`}
        />
        <div className="overview-details-box">
          <div className="overview-details">
            {!isObjEmpty(frontmatter.role_obj) && (
              <div className="overview-role">
                <h4>{frontmatter.role_obj.role_label}</h4>
                <h3>{frontmatter.role_obj.role}</h3>
              </div>
            )}
            {!isObjEmpty(frontmatter.date_obj) && (
              <div className="overview-date">
                <h4>{frontmatter.date_obj.date_label}</h4>
                <h3>{frontmatter.date_obj.date}</h3>
              </div>
            )}
            {frontmatter.team_obj &&
              !isObjEmpty(frontmatter.team_obj) && (
                <div className="overview-team">
                  <h4>{frontmatter.team_obj.team_label}</h4>
                  <h3>{frontmatter.team_obj.team}</h3>
                </div>
              )}
            {!isObjEmpty(frontmatter.skills_tools_obj) && (
              <div className="overview-skills-tools">
                <h4>
                  {frontmatter.skills_tools_obj.skills_tools_label}
                </h4>
                <h3>
                  {frontmatter.skills_tools_obj.skills_tools.map(
                    (item, i) => {
                      if (skillsLen === i + 1) {
                        return `${item}`;
                      } else {
                        return `${item}, `;
                      }
                    },
                  )}
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mdx-body">
        <MDXRenderer>{body}</MDXRenderer>
      </div>
    </div>
  );
};

export default ProjectContent;
